import React, { useEffect, useState } from "react";
import { ProductService } from "../services/product.service";
import { MainButton } from "./MainButton";
import { ProductInterface } from "../interfaces/product.interface";
import { Product } from "./Product";

export function Products({categories, cartProducts, setCartProducts, setShowContactPage, totalPrice, setTotalPrice}: any) {
    const [showBtn, setShowBtn] = useState(false);

    useEffect(() => {
        Telegram.WebApp.BackButton.hide();
    }, []);

    const handleQuantityChange = (productId: number, newQuantity: number) => {
        if (newQuantity < 0) return;
        const updatedProducts = cartProducts.map((product: ProductInterface) => {
            if (product.ID === productId) {
                return {...product, Quantity: newQuantity};
            }
            return product;
        });
        let flag = false;
        for (const product of updatedProducts) {
            if (product.Quantity) {
                flag = true;
                break;
            }
        }
        setShowBtn(flag);
        setCartProducts(updatedProducts);
        setTotalPrice(ProductService.getTotalPrice(updatedProducts));
    };

    const getProductPrice = (productId: number) => {
        return ProductService.getProductPrice(productId, cartProducts);
    }

    return (
        <>
            <div className={`container p-5 mx-auto ${showBtn ? 'mb-12' : ''}`}>
                { categories.map((name: string) =>
                    <>
                        <p className="text-center mb-2 text-lg font-medium">{ name }</p>
                        <div className="grid grid-cols-2 gap-4 mb-5">
                    { cartProducts.filter((product: ProductInterface) => (product.Type == name)).map((product: ProductInterface) => (
                        <Product key={product.ID} product={product} handleQuantityChange={handleQuantityChange}
                        getProductPrice={getProductPrice}/>
                        ))
                    }
                        </div>
                    </>
                )}
            </div>
            {showBtn ? <MainButton onClick={() => setShowContactPage(true)}>Далее ({totalPrice}₾)</MainButton> : ''}
        </>
    )
}