import React, { ChangeEvent, useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import { ClientInterface } from "../interfaces/client.interface";
import { MainButton } from "./MainButton";
import { ClientService } from "../services/client.service";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ru from 'date-fns/locale/ru';
import { ProductService } from "../services/product.service";

registerLocale('ru', ru)

export function Contacts({ submitOrder, setShowContactPage, totalPrice, cartProducts }: any) {
    const [startDate, setStartDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [productText, setProductText] = useState('');
    const [distance, setDistance] = useState<number>(0);
    const [discount, setDiscount] = useState(0);
    const [discountPercent, setDiscountPercent] = useState(0);
    const [showAddress, setShowAddress] = useState<boolean>(false);
    const [showDistance, setShowDistance] = useState<boolean>(false);
    const [printPrepayment, setPrintPrepayment] = useState<boolean>(false);

    const getDeliveryText = (km: number) => {
        if(!km) return '';
        if(km == 1) return `(зона синяя)`;
        if(km == 2) return `(зона желтая)`;
        if(km == 3) return `(зона зеленая)`;
        if(km == 4) return `(зона красная)`;
        if(km == 5) return `(зона оранжевая)`;
        if(km == 6) return `(зона фиолетовая)`;
        return `${ km } км`;
        // <option value="">Не выбрано</option>
        // <option value="1" className="bg-[#cdebff]">3 GEL - Синяя</option>
        // <option value="2" className="bg-[#fae8a0]">4 GEL - Желтая</option>
        // <option value="3" className="bg-[#b6ecae]">5 GEL - Зеленая</option>
        // <option value="4" className="bg-[#f8b5b4]">6 GEL - Красная</option>
        // <option value="5" className="bg-[#ffd4a5]">7 GEL - Оранжевая</option>
        // <option value="6" className="bg-[#f5c1e8]">8 GEL - Фиолетовая</option>
    }

    const [isFreeDelivery, setIsFreeDelivery] = useState<boolean>(false);
    const [formData, setFormData] = useState<ClientInterface>({
        Name: '',
        Instagram: '',
        Telegram: '',
        Phone: '995',
        Address: '',
        Delivery: 'Самовывоз',
        Payment: 'Наличные',
        StartDateGet: '',
        EndDateGet: '',
        Commentary: '',
        Manager: 0,
        Lottery: 0,
        Distance: distance,
        DiscountPercent: discountPercent,
        Discount: discount,
        FreeDelivery: isFreeDelivery
    });
    const [showBtn, setShowBtn] = useState(false);
    const [instagramUser, setInstagramUser] = useState('');

    useEffect(() => {
          const end = new Date(startTime);
          end.setMinutes(startTime.getMinutes() + 30);
          setEndTime(end);
    }, [startTime]);

    useEffect(() => {
        Telegram.WebApp.BackButton.show();
        Telegram.WebApp.BackButton.onClick(() => setShowContactPage(false));

        let prText = 'Ваш заказ: \n';
        cartProducts.forEach((product: any) => {
            if (product.Quantity > 0 && (product.Type !== 'Упаковка')) {
                const price = ProductService.getProductPrice(product.ID, cartProducts)
                prText += `${ product.Name } ${ product.Quantity } шт - ${ price } GEL\n`
            }
        })

        const d = getDiscount();
        if (d) {
            prText += `Скидка: ${ d } GEL\n`;
        }

        const del = getDeliveryCost();
        if (del) {
            prText += `Доставка ${ getDeliveryText(distance) } – ${ del } GEL\n`;
        } else if(isFreeDelivery) {
            prText += `Доставка – Бесплатно\n`;
        }

        prText += `Итого: ${ getTotalPrice() } GEL`;

        if (printPrepayment) {
            prText += `\n\nОплату можно внести на карту TBC, Bank of Georgia или на карту русского банка по курсу «золотой короны».
\nПри самовывозе или внутренней доставке возможна оплата наличными.
Какой способ вам подходит?`
        }

        setProductText(prText);
    }, [discount, discountPercent, distance, printPrepayment, isFreeDelivery]);

    const getDiscount = () => {
        if (!discountPercent && !discount) return 0;
        let dis = 0;
        if (discountPercent) {
            dis += parseFloat((discountPercent / 100 * totalPrice).toFixed(2));
        }
        return dis + (discount || 0);
    }

    const getDeliveryCost = () => {
        if(isFreeDelivery) return 0;
        if (!showDistance || !distance) return 0;
        return distance == 1 ? 3 : (distance - 1) + 3;
    }

    const getTotalPrice = () => {
        const d = getDiscount();
        const delivery = getDeliveryCost();
        const tp = parseFloat(totalPrice);

        let t = d ? tp - d : tp;
        return delivery ? t + delivery : t;
    }

    const copy = () => {
        const textarea = document.getElementById('product-text');
        if (textarea instanceof HTMLTextAreaElement) {
            textarea.select();
            document.execCommand('copy');
        }
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | any>, fieldName = '') => {
        const [ name, value ] = e.target?.type === 'checkbox' ? [fieldName || e.target.name, e.target.checked] : [fieldName || e.target.name, e.target.value];
        if (name === 'Instagram') changeInstagram(value);
        if (name == 'Delivery') changeDelivery(value);

        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };
    //
    const changeInstagram = (value: string) => {
        setShowBtn(Boolean(value.trim()));
    }

    const changeDelivery = (value: string) => {
        setShowAddress(value !== 'Самовывоз');
        setShowDistance(value === 'Доставка Сырничек')
    }

    const getUserByInstagram = (instagram: string) => {
        if (!instagram.trim()) return;
        if (instagramUser === instagram) return;

        setInstagramUser(instagram);
        ClientService.setInstagramClient(instagram).then(result => {
            const user = result.data;
            if(user.Instagram) {
                setFormData((prevData: any) => ({
                    ...prevData,
                    Name: user.Name,
                    Phone: user.Phone,
                    Address: user.Address,
                    Distance: user.Distance
                }));
                setDistance(user.Distance);
            }
        });
    }

    const getSQLDateFormat = (date: Date): string => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${ year }-${ month }-${ day } ${ hours }:${ minutes }:${ seconds }`;
    }

    const submitBtn = () => {
        const date = startDate;
        const startDateStr = new Date(date);
        startDateStr.setHours(startTime.getHours());
        startDateStr.setMinutes(startTime.getMinutes());

        const endDateStr = new Date(date);
        endDateStr.setHours(endTime.getHours());
        endDateStr.setMinutes(endTime.getMinutes());


        submitOrder({
            ...formData,
            Phone: formData.Phone.trim(),
            StartDateGet: getSQLDateFormat(startDateStr),
            EndDateGet: getSQLDateFormat(endDateStr),
        }, setShowBtn);
    }

    const selectDistance = (e: any) => {
        if(e.target.value) {
            setDistance(e.target.value);
            handleChange(e, 'Distance');
        }
    }

    return (
        <div className="container p-5">
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="name">Имя:</label>
                <input className="col-span-9 border-b border-brown bg-transparent outline-none" type="text" id="name"
                       name="Name" value={ formData.Name } onChange={ handleChange }/>
            </div>
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="instagram">Ник:</label>
                <input className="col-span-9 border-b border-brown bg-transparent outline-none" type="text"
                       id="Instagram" name="Instagram" value={ formData.Instagram } onChange={ handleChange }
                       onBlur={ (event) => getUserByInstagram(event.target.value) }/>
            </div>
            {/*<div className="grid grid-cols-12 gap-4 mb-5">*/ }
            {/*    <label className="col-span-3" htmlFor="telegram">Telegram:</label>*/ }
            {/*    <input className="col-span-9 border-b border-brown bg-transparent outline-none" type="text" id="telegram" name="telegram" value={formData.telegram} onChange={handleChange} />*/ }
            {/*</div>*/ }
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="phone">Телефон:</label>
                <InputMask className="col-span-9 border-b border-brown bg-transparent outline-none"
                           mask="+999999999999" maskChar=" " value={ formData.Phone } id="Phone" name="Phone"
                           onChange={ handleChange }/>
            </div>
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="Delivery">Способ получения:</label>
                <select className="col-span-9 border-b border-brown bg-transparent outline-none" id="Delivery"
                        name="Delivery" onChange={ handleChange }>
                    <option value="Самовывоз">Самовывоз</option>
                    <option value="Доставка">Доставка</option>
                    <option value="Доставка Сырничек">Доставка "Сырничек"</option>
                </select>
            </div>
            <div className={`grid grid-cols-12 gap-4 mb-5 ${ showAddress ? '' : 'hidden'}`}>
                <label className="col-span-3" htmlFor="Address">Адрес:</label>
                <input className="col-span-9 border-b border-brown bg-transparent outline-none" type="text" id="Address"
                       name="Address" value={ formData.Address } onChange={ handleChange }/>
            </div>
            { showDistance ? <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="zones">Зона:</label>
                <select value={ distance } id="zones" onChange={ selectDistance } className="col-span-9 border-b border-brown bg-transparent outline-none">
                    <option value="">Не выбрано</option>
                    <option value="1" className="bg-[#cdebff]">3 GEL - Синяя</option>
                    <option value="2" className="bg-[#fae8a0]">4 GEL - Желтая</option>
                    <option value="3" className="bg-[#b6ecae]">5 GEL - Зеленая</option>
                    <option value="4" className="bg-[#f8b5b4]">6 GEL - Красная</option>
                    <option value="5" className="bg-[#ffd4a5]">7 GEL - Оранжевая</option>
                    <option value="6" className="bg-[#f5c1e8]">8 GEL - Фиолетовая</option>
                </select>
            </div> : <></> }
            { showDistance ? <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="Distance">Расстояние:</label>
                <input className="col-span-9 border-b border-brown bg-transparent outline-none" type="number"
                       id="Distance" name="Distance" value={ formData.Distance }
                       onChange={ handleChange }
                       onBlur={ (e) => {
                           setDistance(parseFloat(e.target.value));
                           handleChange(e)
                       } }/>
            </div> : <></> }
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="Payment">Оплата:</label>
                <select className="col-span-9 border-b border-brown bg-transparent outline-none" id="Payment"
                        name="Payment" onChange={ handleChange }>
                    <option value="Наличные">Наличные</option>
                    <option value="Bank of Georgia">Bank of Georgia</option>
                    <option value="TBC">TBC</option>
                    <option value="Тинькофф">Тинькофф</option>
                    <option value="Сбербанк">Сбербанк</option>
                </select>
            </div>
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="getDate">Дата получения:</label>
                <div className="col-span-9">
                    <DatePicker
                        className="border-b border-brown bg-transparent outline-none mb-2"
                        selected={ startDate }
                        onChange={ (date: any) => {
                            setStartDate(date);
                        } }
                        locale="ru"
                        calendarStartDay={ 1 }
                        dateFormat="dd.MM.y"
                    />
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-6">
                            <DatePicker
                                className="border-b border-brown bg-transparent outline-none w-full"
                                selected={ startTime }
                                onChange={ (date: any) => {
                                    setStartTime(date);
                                } }
                                locale="ru"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={ 15 }
                                timeCaption="Time"
                                dateFormat="HH:mm"
                            />
                        </div>
                        <div className="col-span-6">
                            <DatePicker
                                className="border-b border-brown bg-transparent outline-none w-full"
                                selected={ endTime }
                                onChange={ (date: any) => {
                                    setEndTime(date);
                                } }
                                locale="ru"
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={ 15 }
                                timeCaption="Time"
                                dateFormat="HH:mm"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="DiscountPercent">Скидка %:</label>
                <input className="col-span-9 border-b border-brown bg-transparent outline-none" type="number"
                       id="DiscountPercent" name="DiscountPercent" value={ formData.DiscountPercent }
                       onChange={ handleChange }
                       onBlur={ (e) => {
                           setDiscountPercent(parseFloat(e.target.value));
                           handleChange(e)
                       } }/>
            </div>
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="Discount">Скидка ₾:</label>
                <input className="col-span-9 border-b border-brown bg-transparent outline-none" type="number"
                       id="Discount" name="Discount" value={ formData.Discount }
                       onChange={ handleChange }
                       onBlur={ (e) => {
                           setDiscount(parseFloat(e.target.value));
                           handleChange(e)
                       } }/>
            </div>
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="Commentary">Коммент:</label>
                <input className="col-span-9 border-b border-brown bg-transparent outline-none" type="text"
                       id="Commentary" name="Commentary" value={ formData.Commentary }
                       onChange={ handleChange }/>
            </div>
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="printPrepayment">Предоплата:</label>
                <input className="col-span-1 border-b border-brown bg-transparent outline-none" type="checkbox"
                       id="printPrepayment" name="printPrepayment" value={ printPrepayment ? 1 : 0 }
                       onChange={ (e) => setPrintPrepayment(e.target.checked) }
                />
            </div>
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="FreeDelivery">Бесплатная доставка:</label>
                <input className="col-span-1 border-b border-brown bg-transparent outline-none" type="checkbox"
                       id="FreeDelivery" name="FreeDelivery" checked={ isFreeDelivery }
                       onChange={ (e) => {
                           setIsFreeDelivery(e.target.checked);
                           handleChange(e);
                       } }
                />
            </div>
            <div className="grid grid-cols-12 gap-4 mb-5">
                <label className="col-span-3" htmlFor="Manager">Менеджер:</label>
                <input className="col-span-1 border-b border-brown bg-transparent outline-none" type="checkbox"
                       id="Manager" name="Manager" value={ formData.Manager } checked={ !!formData.Manager }
                       onChange={ handleChange }
                />
            </div>
            {/*<div className="grid grid-cols-12 gap-4 mb-5">*/}
            {/*    <label className="col-span-3" htmlFor="Lottery">Участвует в лотерее:</label>*/}
            {/*    <input className="col-span-1 border-b border-brown bg-transparent outline-none" type="checkbox"*/}
            {/*           id="Lottery" name="Lottery" value={ formData.Lottery } checked={ !!formData.Lottery }*/}
            {/*           onChange={ handleChange }*/}
            {/*    />*/}
            {/*</div>*/}
            <div>
                <button className="border border-brown bg-brown text-white mb-2 px-2 py-1" onClick={ () => copy() }>Скопировать</button>
                <textarea id="product-text" className="w-full min-h-[200px]" value={ productText }></textarea>
            </div>
            { showBtn ?
                <MainButton onClick={ () => submitBtn() }>Создать ({ getTotalPrice() }₾)</MainButton> : '' }
        </div>
    )
}