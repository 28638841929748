import axios from "axios";
import { environment } from "../environments/environment";
import { ProductInterface } from "../interfaces/product.interface";

export const ProductService = {
    getProducts() {
        return axios.get(`${environment.apiUrl}/products`);
    },

    setProducts(setState: Function, setCategories: Function) {
        this.getProducts().then(result => {
            setState(result.data);
            result.data.forEach((product: ProductInterface) => {
                setCategories((old: string[]) => {
                    if(!old.includes(product.Type)) old.push(product.Type);
                    return old;
                })
            });
        })
    },

    getProductPrice(productId: number, products: ProductInterface[]): number {
        const price = (productId: number) => {
            for (const product of products) {
                if(product.ID === productId) {
                    if(!product.Quantity) return 0;
                    if(!product.Discounts) return product.Price * product.Quantity;

                    const discounts = Object.keys(product.Discounts);
                    for (const discountsKey in discounts) {
                        const count = parseInt(discounts[discountsKey]);
                        const nextCount = parseInt(discounts[parseInt(discountsKey) + 1]);
                        if(product.Quantity >= count && product.Quantity < nextCount) {
                            return product.Discounts[count] * product.Quantity;
                        }
                    }

                    const min = Math.min(...discounts.map(Number));
                    if(product.Quantity < min) return product.Discounts[min] * product.Quantity;

                    const max = Math.max(...discounts.map(Number));
                    return product.Discounts[max] * product.Quantity;
                }
            }
            return 0;
        };
        return parseFloat(price(productId).toFixed(1));
    },

    getTotalPrice(products: ProductInterface[]) {
        let totalPrice = 0;
        for (const product of products) {
            totalPrice += this.getProductPrice(product.ID, products);
        }
        return totalPrice.toFixed(1);
    }
}