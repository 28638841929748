import React, { useEffect, useState } from 'react';
import { Products } from "./components/Products";
import { ProductInterface } from "./interfaces/product.interface";
import { Contacts } from "./components/Contacts";
import { ClientInterface } from "./interfaces/client.interface";
import { ProductService } from "./services/product.service";
import { ClientService } from "./services/client.service";

function App() {
    const [showContactPage, setShowContactPage] = useState(false);
    const [cartProducts, setCartProducts] = useState<ProductInterface[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [categories, setCategories] = useState<string[]>([]);


    useEffect(() => {
        ProductService.setProducts(setCartProducts, setCategories);
    }, []);

    const submitOrder = (formData: ClientInterface, setShowBtn: Function) => {
        setShowBtn(false);
        Telegram.WebApp.BackButton.hide();

        ClientService.createOrder(formData, cartProducts).then(result => {
            Telegram.WebApp.showAlert('Заказ успешно создан');
        });
    }

    return (
        <>
            {!showContactPage ? <Products categories={categories} cartProducts={cartProducts} setCartProducts={setCartProducts}
                                          setShowContactPage={setShowContactPage} totalPrice={totalPrice}
                                          setTotalPrice={setTotalPrice}/> :
                <Contacts submitOrder={submitOrder} setShowContactPage={setShowContactPage} totalPrice={totalPrice} cartProducts={cartProducts}/>}
        </>
    )
}

export default App;
