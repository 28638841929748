import axios from "axios";
import { environment } from "../environments/environment";
import { ClientInterface } from "../interfaces/client.interface";
import { ProductInterface } from "../interfaces/product.interface";

export const ClientService = {
    async setInstagramClient(instagram: string) {
        return await axios.get(`${environment.apiUrl}/client`, {
            params: { instagram }
        });
    },

    createOrder(formData: ClientInterface, cartProducts: ProductInterface[]) {
        const products: any = {};
        cartProducts.filter((product: ProductInterface) => (product.Quantity)).forEach((product: any) => {
            products[product.ID] = product.Quantity;
        });

        return axios.post(`${environment.apiUrl}/createOrder`, {
            client: formData,
            products
        });
    }
}