import React from "react";
import "../css/product.css"
import { ProductInterface } from "../interfaces/product.interface";

export function Product ({ product, handleQuantityChange, getProductPrice }: any) {
    return (
        <div
            key={product.ID}
            className={`border-2 ${ product.Count < product.Quantity ? 'border-red-600' : 'border-brown' }  p-4 rounded flex flex-col items-center justify-between`}
        >
            <h2 className="text-md font-semibold mb-2 text-center">{ product.Name }</h2>
            <p className="text-gray-600">{ getProductPrice(product.ID) } ₾</p>
                <div className="mt-2 flex">
                    <button
                        className="bg-light rounded px-1.5"
                        onClick={() =>
                            handleQuantityChange(product.ID, product.Quantity - 1)
                        }
                    >-</button>
                    <input type="number" className="border w-full text-sm text-center mx-2 border-brown rounded" value={product.Quantity} onChange={(event) => handleQuantityChange(product.ID, parseInt(event.target.value))}/>
                    <button
                        className="bg-light rounded px-1.5"
                        onClick={() =>
                            handleQuantityChange(product.ID, product.Quantity + 1)
                        }
                    >+</button>
                </div>
            <p className="text-sm">Остаток: { product.Count }</p>
        </div>
    )
}